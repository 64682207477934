.welcome {
  @include make-row();

  .info {
    @include make-xs-column(12);
    text-align: center;
  }

  p {
    @extend .lead;
  }
}

.login-box {
  @extend .panel-body;
  background-color:#eee;
}

.login-form {
  @extend .form-horizontal;
  > div {
    @extend .form-group;
  }

  > div > label {
    @include make-md-column(5);
    @extend .control-label;
  }

  .control {
    @include make-md-column(7);
  }

  .control > input {
    @extend .form-control;
  }

  .orphan {
    @include make-md-column-offset(5);
  }

  .remember {
    @include make-md-column-offset(5);
  }

  button {
    @extend .btn;
    @extend .btn-primary;
    margin-right: 15px;
  }
}

.vull {
  text-align:center;
}