@import "bootstrap";

@import "login/login";
@import "socis/mainform";

.navbar {
  margin-bottom: 0;
}

body {
  background-color:#A90006;
  font-family: Lato,"Helvetica Neue", Helvetica, Arial, sans-serif;

  a {
    color:#23527c;
  }
}

.body-white {
  background-color:white;
}

.screen-title {
  @extend .h2;
  color: wheat;
  font-weight: 900;
}

.acia-navbar {
  @extend .navbar-default;
  background-color:#a90006;
}
.navbar-default .navbar-nav > li > a,
.menu-nav {
  color:wheat;
}

.area-soci {

  p { font-size:18px}
  /* p { color: #f6f6f6; font-family: 'Orienta', sans-serif; font-size: 16px; line-height: 24px; margin: 0 0 24px; text-align: center; }

   a { color: #e8d4a7; text-decoration: none; text-align: right; padding: 15px; font-size: 20px; }
 */
  a {
    color: blue;
  }
  .section-title {
    font-weight: 900;
    text-align: left;
    margin-bottom: 1em;
  }
}

.input-disabled {
  background:lightgrey !important;
  color:grey !important;
}

.input-label-disabled {
  color:lightgray !important;
}